@import "https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap";
:root {
  --bg-color: #ffdf99;
  --light-bg: #ffdf99;
  --dark-bg: #80dfa6;
  --text-color: #000;
  --secondary-color: #5139ee;
}

::selection {
  background: var(--secondary-color);
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Rubik, sans-serif;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  background: var(--bg-color);
  color: var(--text-color);
  scrollbar-width: thin;
  scrollbar-color: #000 transparent;
  background-image: linear-gradient(to right, #8080805c 1px, #0000 1px), linear-gradient(#8080805c 1px, #0000 1px);
  background-size: 140px 140px;
  line-height: 1.5;
  transition: background .7s ease-in-out;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: none;
}

body::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 0;
}

svg {
  max-width: 100%;
  display: block;
}

button {
  font: inherit;
}

a {
  font: inherit;
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

.port--container {
  width: 100%;
  max-width: 3000px;
  opacity: 1;
  margin: 0 auto;
  padding: 3% 5%;
  animation-name: easein;
  animation-duration: 1.7s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes easein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.port--header {
  flex-direction: column;
  display: flex;
  position: relative;
}

.port--header_nav {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3em;
  display: flex;
}

.port--header_nav a {
  font-size: 1.1rem;
  font-weight: 400;
}

.port--header_heading {
  text-transform: uppercase;
  margin: 8vw 0;
  font-size: 12.2vw;
  font-weight: 500;
  line-height: 1.1;
}

.port--header_text {
  font-size: 4vw;
  font-weight: 300;
}

.port--stack {
  position: absolute;
  top: 5em;
  right: 0;
}

.port--stack_heading {
  text-align: right;
  text-transform: capitalize;
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: underline;
}

.port--stack_list {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.port--tint {
  color: var(--secondary-color);
}

.port--underline {
  position: relative;
}

.port--underline:after {
  content: "";
  width: 0%;
  height: 2px;
  background-color: var(--text-color);
  transition: width .7s ease-in;
  position: absolute;
  bottom: 0;
  left: 0;
}

.port--underline:hover:after {
  width: 100%;
}

.port--switch {
  border: 5px solid var(--text-color);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  position: fixed;
  bottom: 10%;
  right: 5%;
  overflow: hidden;
}

.port--switch_light, .port--switch_dark {
  width: 50%;
  height: 100%;
  cursor: pointer;
  border: none;
}

.port--switch_light {
  background: var(--light-bg);
  border-right: 2px solid var(--text-color);
}

.port--switch_dark {
  background: var(--dark-bg);
  border-left: 2px solid var(--text-color);
}

.port--section {
  margin: 10em 0;
}

.port--subheading {
  text-transform: capitalize;
  margin-bottom: .9em;
  font-size: 6.5vw;
  font-weight: 400;
  line-height: 1.1;
  text-decoration: underline;
}

.port--work, .port--certifications {
  flex-direction: column;
  row-gap: 4.5em;
  display: flex;
}

.port--list {
  flex-direction: column;
  row-gap: 1.5em;
  display: flex;
}

.port--list_title {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 3.5vw;
  font-weight: 400;
  line-height: 1.1;
  transition: all .2s ease-in-out .2s;
}

.port--list_title:hover {
  letter-spacing: 0;
  color: var(--secondary-color);
}

.port--list_text {
  letter-spacing: 1.2px;
  font-size: 2.5vw;
  font-weight: 300;
}

.port--list_stack {
  text-transform: lowercase;
  flex-wrap: wrap;
  column-gap: .5em;
  font-size: 1.5vw;
  display: flex;
}

.port--nav {
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1.5em;
  display: flex;
}

.port--nav a {
  letter-spacing: 2px;
  font-weight: 300;
}

.port--footer {
  justify-content: space-between;
  align-items: center;
  margin-top: 2em;
  font-size: 1.3rem;
  display: flex;
}

.port--cursor {
  pointer-events: none;
  z-index: 10000;
  border: 1px solid var(--text-color);
  height: 50px;
  width: 50px;
  background: none;
  border-radius: 50%;
  transition: all .3s ease-out;
  position: fixed;
  top: 50%;
  left: -100px;
  transform: translateX(-50%)translateY(-50%);
}

@media screen and (max-width: 800px) {
  .port--header_text {
    font-size: 22px;
  }

  .port--subheading {
    font-size: 40px;
  }

  .port--list_title {
    font-size: 27px;
  }

  .port--list_text {
    font-size: 21px;
  }

  .port--list_stack {
    font-size: 19px;
  }

  .port--switch {
    bottom: 6%;
  }
}

@media screen and (max-width: 512px) {
  .port--header_heading {
    font-size: 59px;
  }

  .port--stack {
    margin-top: 1.5em;
    position: static;
  }

  .port--stack_heading {
    text-align: left;
  }

  .port--stack_list {
    flex-flow: wrap;
    column-gap: .5em;
  }

  .port--cursor {
    display: none;
  }
}

/*# sourceMappingURL=index.e00eae95.css.map */
