@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');

:root {
  --bg-color: #ffdf99;
  --light-bg: #ffdf99;
  --dark-bg: #80dfa6;
  --text-color: #000;
  --secondary-color: #5139EE;
}

::selection {
  background: var(--secondary-color);
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
}

html, body {
  height: 100%;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  background: var(--bg-color);
  color: var(--text-color);
  background-size: 140px 140px;
  background-image: linear-gradient(
      to right,
      rgba(128, 128, 128, 0.36) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(128, 128, 128, 0.36) 1px, transparent 1px);
  scrollbar-width: thin;
  scrollbar-color: #000 transparent; 
  transition: background ease-in-out .7s;
}

body::-webkit-scrollbar {
  width: 10px; 
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 0px; 
}

svg {
  display: block;
  max-width: 100%;
}

button {
  font: inherit;
}

a {
  font: inherit;
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

.port--container {
  width: 100%;
  max-width: 3000px;
  padding: 3% 5%;
  margin: 0 auto;
  opacity: 1;
  animation-name: easein;
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes easein {
  from {opacity: 0;}
  to {opacity: 1;}
}

.port--header {
  position: relative;
  display: flex;
  flex-direction: column;
}

.port--header_nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3em;
}

.port--header_nav a {
  font-size: 1.1rem;
  font-weight: 400;
}

.port--header_heading {
  font-size: 12.2vw;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.1;
  margin: 8vw 0;
}

.port--header_text {
  font-size: 4vw;
  font-weight: 300;
}

.port--stack {
  position: absolute;
  top: 5em;
  right: 0;
}

.port--stack_heading {
  text-align: right;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: underline;
}

.port--stack_list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.port--tint {
  color: var(--secondary-color);
}

.port--underline {
  position: relative;
}

.port--underline::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  height: 2px;
  background-color: var(--text-color);
  transition: width ease-in .7s;
}

.port--underline:hover::after {
  width: 100%;
}

.port--switch {
  position: fixed;
  right: 5%;
  bottom: 10%;
  border: 5px solid var(--text-color);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
}

.port--switch_light, .port--switch_dark {
  width: 50%;
  height: 100%;
  border: none;
  cursor: pointer;
}

.port--switch_light {
  background: var(--light-bg);
  border-right: 2px solid var(--text-color);
}

.port--switch_dark {
  background: var(--dark-bg);
  border-left: 2px solid var(--text-color);
}

.port--section {
  margin: 10em 0;
}

.port--subheading {
  font-size: 6.5vw;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 1.1;
  text-decoration: underline;
  margin-bottom: .9em;
}

.port--work, .port--certifications {
  display: flex;
  flex-direction: column;
  row-gap: 4.5em;
}

.port--list {
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
}

.port--list_title {
  font-size: 3.5vw;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.1;
  letter-spacing: 1.5;
  transition: all ease-in-out .2s;
  transition-delay: .2s;
  width: fit-content;
}

.port--list_title:hover {
  letter-spacing: 0;
  color: var(--secondary-color);
}

.port--list_text {
  font-size: 2.5vw;
  font-weight: 300;
  letter-spacing: 1.2;
}

.port--list_stack {
  display: flex;
  flex-wrap: wrap;
  column-gap: .5em;
  font-size: 1.5vw;
  text-transform: lowercase;
}

.port--nav {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1.5em;
}

.port--nav a {
  font-weight: 300;
  letter-spacing: 2;
}

.port--footer {
  margin-top: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3rem;
}

.port--cursor {
  position: fixed;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
  left: -100px;
  top: 50%;
  background: transparent;
  z-index: 10000;
  border: 1px solid var(--text-color);
  height: 50px;
  width: 50px;
  transition: all 300ms ease-out;
}

@media screen and (max-width: 800px) {
  
  .port--header_text {
    font-size: 22px;
  }

  .port--subheading {
    font-size: 40px;
  }

  .port--list_title {
    font-size: 27px;
  }

  .port--list_text {
    font-size: 21px;
  }

  .port--list_stack {
    font-size: 19px;
  }

  .port--switch {
    bottom: 6%;
  }

}

@media screen and (max-width: 512px) {
  .port--header_heading {
    font-size: 59px;
  }

  .port--stack {
    margin-top: 1.5em;
    position: static;
  }

  .port--stack_heading {
    text-align: left;
  }

  .port--stack_list {
    flex-direction: row;
    column-gap: .5em;
    flex-wrap: wrap;
  }

  .port--cursor {
    display: none;
  }
}
